//Mui:
import { Grid } from '@mui/material'
//Routes:
import { Route, Routes } from 'react-router-dom'
//Components:
import NavMenu from './components/NavMenu/NavMenu'
import Footer from './components/Footer/Footer'
import WhatsAppFlotante from './components/WhatsAppFlotante/WhatsAppFlotante'
//Pages:
import Home from './pages/Home/Home'
import Ajax from './pages/Ajax/Ajax'
import Risco from './pages/Risco/Risco'
import Empresa from './pages/Empresa/Empresa'
import Tips from './pages/Tips/Tips'
import SmartPanicsFlota from './pages/SmartPanicsFlota/SmartPanicsFlota'
import PersonalCustodias from './pages/PersonalCustodias/PersonalCustodias'
import SmartHome from './pages/SmartHome/SmartHome'
import Hellgrun from './pages/Hellgrun/Hellgrun'

const App = () => {

    return (
        <Grid container minHeight='100vh'>
            <NavMenu />
            <Grid container minHeight='60vh'>
                <Routes>
                    <Route exact path='/' element={<Home />} />
                    <Route exact path='/smart-panics-flota' element={<SmartPanicsFlota />} />
                    <Route exact path='/ajax' element={<Ajax />} />
                    <Route exact path='/risco' element={<Risco />} />
                    <Route exact path='/personal-custodias' element={<PersonalCustodias />} />
                    <Route exact path='/smart-home' element={<SmartHome />} />
                    <Route exact path='/hellgrun' element={<Hellgrun />} />
                    <Route exact path='/empresa' element={<Empresa />} />
                    <Route exact path='/tips' element={<Tips />} />
                    <Route exact path='/*' element={<Home />} />
                </Routes>
                <WhatsAppFlotante />
            </Grid>
            <Footer />
        </Grid>
    )
}

export default App;
