import { useState } from "react"
//Mui:
import { Grid, TextField, Typography } from "@mui/material"
//Components:
import CustomButon from "../../components/CustomButton/CustomButton"
//Utils:
import { colors } from "../../utils/const"
import { whatsApp } from "../../utils/utils"
import { ErrorOutline } from "@mui/icons-material"

const Cotizacion = () => {

    const [nombre, setNombre] = useState('')
    const [descripcion, setDescripcion] = useState('')
    const [error, setError] = useState(false)

    const sendWhatsApp = () => {
        if(nombre && descripcion){
            setError(false)
            let mensaje = `Hola! Soy ${nombre} y estoy interesado en ${descripcion}.`
            whatsApp(mensaje)
        }else{
            setError(true)
        }
    }

    return (
        <Grid container alignItems='center' gap={{xs: 3, md: 0}} sx={{
            background: colors.lightOrange,
            padding: {xs:'40px 30px', md:'50px 50px'},
            borderRadius:'10px'
        }}>
            <Grid item xs={12} md={4}>
                <Typography fontSize={{sx:'12pt', md:'16pt'}} sx={{textAlign: {xs: 'center', md: 'left'}, fontWeight:{xs:600, md:500}}}>
                    Obtené una cotización sin cargo personalizada que se adapte a tus posibilidades y necesidades.
                </Typography>
            </Grid>
            <Grid item xs={12} md={5} container>
                <Grid item xs={12} sx={{display:'flex', gap:{xs:'10px', md:'30px'}}}>
                    <TextField 
                        value={nombre}
                        onChange={e => setNombre(e.target.value)}
                        label='Nombre y Apellido'
                        variant='standard'
                        sx={{minWidth:{xs:null, md:'50%'}}}
                    />
                    <TextField 
                        value={descripcion}
                        onChange={e => setDescripcion(e.target.value)}
                        label='Estoy interesado en...'
                        variant='standard'
                        sx={{minWidth:{xs:null, md:'50%'}}}
                    />
                </Grid>
                { error && (
                    <Grid item xs={12} container gap={1} sx={{marginTop:'10px'}}>
                        <ErrorOutline sx={{color: colors.grey}}/>
                        <Typography>
                            Todos los campos son obligatorios.
                        </Typography>
                    </Grid>
                )}
            </Grid>
            <Grid item xs={12} md={3} sx={{textAlign: {xs: 'center', md: 'right'}, marginTop:{xs:'15px', md:'0px'}}}>
                <CustomButon 
                    label='OBTENER COTIZACIÓN' 
                    fontSize={{xs:'8pt', md:'10pt'}} 
                    onClick={sendWhatsApp}
                    variant='contained'
                />
            </Grid>
        </Grid>
    )
}

export default Cotizacion