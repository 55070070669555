import { Grid, Typography } from "@mui/material"
//Components:
import ResponsiveImg from "../../components/ResponsiveImg/ResponsiveImg"
//Assets:
import img01cell from '../../assets/hellgrun/01_cell.jpg'
import img01desk from '../../assets/hellgrun/01_desk.jpg'
import img02cell from '../../assets/hellgrun/02_cell.jpg'
import img02desk from '../../assets/hellgrun/02_desk.jpg'
import img03cell from '../../assets/hellgrun/03_cell.jpg'
import img03desk from '../../assets/hellgrun/03_desk.jpg'
import img04cell from '../../assets/hellgrun/04_cell.jpg'
import img04desk from '../../assets/hellgrun/04_desk.jpg'
import img05cell from '../../assets/hellgrun/05_cell.jpg'
import img05desk from '../../assets/hellgrun/05_desk.jpg'
import img06desk from '../../assets/hellgrun/06_desk.jpg'
import img06cell from '../../assets/hellgrun/06_cell.jpg'
//Utils:
import { colors } from "../../utils/const"

const Hellgrun = () => {

    const textosDesktop = {
        text1: {
            title: 'Tu hogar monitoreado 24/7',
            body1: (
                <span>
                    El sistema proporciona<br/>monitoreo constante,<br/>asegurando que tu propiedad<br/>esté protegida todo el tiempo.,
                </span>
            ),
            body2: (
                <span>
                    Un equipo de expertos está<br/>siempre listo para responder a<br/>cualquier emergencia de<br/>manera rápida y eficiente.
                </span>   
            )
        },
        text2: {
            title:'Tecnología avanzada de detección',
            body1: (
                <span>
                    Sensores de alta precisión y cámaras de gran<br/>definición detectan cualquier actividad<br/>sospechosa y alertan de inmediato.
                </span>
            ),
            body2: [
                'Sensor piroeléctrico (Dual PIR)',
                'Banda 915 Mhz',
                'Detección de intrusión y de control',
                'Distancia a campo abierto de 300 mts',
                'Pila CR-123 con una duración de hasta 4 años'
            ]
        },
        text3: {
            title: 'Automatización del Hogar',
            body: (
                <span>
                    La seguridad y la inteligencia se<br/>combinan convirtiendo tu hogar en un<br/>bastión impenetrable de tranquilidad y<br/>protección. Con Hellgrün, tu casa no solo<br/>está protegida contra intrusos, sino que<br/>también puedes controlar y automatizar<br/>diversos aspectos de tu hogar. Desde la<br/>iluminación y la climatización hasta las<br/>cerraduras y cámaras de seguridad, todo<br/>está conectado y gestionado desde una<br/>única plataforma.
                </span>
            )
        },
        text4: {
            title: 'Control total de tu hogar con Hellgrün Check',
            body1: (
                <span>
                    Recibí notificaciones en tiempo real;<br/>accede a tus cámaras; activá y descativá<br/>tu alarma. El control total de tu hogar en<br/>tu dispositivo móvil, estes donde estes.
                </span>
            ),
            body2: [
                'Multi Alarma: 8 alarmas en una sola App',
                'Comandos inmeditos',
                'Fácil usabilidad y navegación',
                'Distintos niveles de acceso según el usuario',
            ]
        },
        text5: {
            title: 'Protección Ininterrumpida',
            body: (
                <span>
                    La conectividad 4G asegura<br/>una comunicación constante,<br/>incluso cuando la red WiFi puede<br/>no estar disponible, garantizando así una<br/>respuesta rápida y efectiva ante cualquier eventualidad,<br/> proporcionando una capa adicional de seguridad.
                </span>
            )
        },
    }

    const textosCell = {
        text1: {
            title: 'Tu hogar monitoreado 24/7',
            body1: (
                <span>
                    El sistema proporciona monitoreo<br/>constante, asegurando que tu propiedad<br/>esté protegida todo el tiempo.
                </span>
            ),
            body2: (
                <span>
                    Un equipo de expertos está siempre listo<br/>para responder a cualquier emergencia<br/>de manera rápida y eficiente.
                </span>   
            )
        },
        text2: {
            title:'Tecnología avanzada de detección',
            body1: (
                <span>
                    Sensores de alta precisión y<br/>cámaras de gran definición<br/>detectan cualquier actividad<br/>sospechosa y alertan de inmediato.
                </span>
            ),
            body2: [
                'Sensor piroeléctrico (Dual PIR)',
                'Banda 915 Mhz',
                'Detección de intrusión y de control',
                'Distancia a campo abierto de 300 mts',
                'Pila CR-123 con una duración de hasta 4 años'
            ]
        },
        text3: {
            title: 'Automatización del Hogar',
            body: (
                <span>
                    La seguridad y la inteligencia se<br/>combinan convirtiendo tu hogar en un<br/>bastión impenetrable de tranquilidad y<br/>protección. Con Hellgrün, tu casa no solo<br/>está protegida contra intrusos, sino que<br/>también puedes controlar y automatizar<br/>diversos aspectos de tu hogar. Desde la<br/>iluminación y la climatización hasta las<br/>cerraduras y cámaras de seguridad, todo<br/>está conectado y gestionado desde una<br/>única plataforma.
                </span>
            )
        },
        text4: {
            title: (
                <span>
                    Control total de tu hogar<br/>con Hellgrün Check
                </span>
            ),
            body1: (
                <span>
                    Recibí notificaciones en tiempo real;<br/>accede a tus cámaras; activá y descativá<br/>tu alarma. El control total de tu hogar en<br/>tu dispositivo móvil, estes donde estes.
                </span>
            ),
            body2: [
                'Multi Alarma: 8 alarmas en una sola App',
                'Comandos inmeditos',
                'Fácil usabilidad y navegación',
                'Distintos niveles de acceso según el usuario',
            ]
        },
        text5: {
            title: 'Protección Ininterrumpida',
            body: (
                <span>
                    La conectividad 4G asegura<br/>una comunicación constante,<br/>incluso cuando la red WiFi puede<br/>no estar disponible, garantizando así una<br/>respuesta rápida y efectiva ante cualquier eventualidad,<br/> proporcionando una capa adicional de seguridad.
                </span>
            )
        },
    }

    return (
        <Grid container alignItems='flex-start' sx={{ background:'#e8e8e8'}}>
                
            <Grid item xs={12} container alignItems='flex-end'>
                <ResponsiveImg 
                    imgCell={img01cell}
                    imgDesk={img01desk}
                    alt='banner'
                    onClick={()=>{}}
                />
            </Grid>

            <Grid item xs={12} container alignItems='flex-end'>
                <Typography sx={{
                    padding: {xs:'5% 7%', md:'1% 15% 4% 15%'},
                    fontSize: {xs:'14pt', md:'24pt'},
                    textAlign: 'center',
                    fontWeight: 700
                }}>
                    Junto a Hellgrün, proporcionamos protección integral a hogares y negocios, asegurando productos de seguridad electrónica de alta calidad a precios competitivos.
                </Typography>
            </Grid>

            <Grid item xs={12} container alignItems='flex-end' sx={{background: colors.black}}>
                <Grid item xs={12} md={0} sx={{display:{xs:'block', md:'none'}}}>
                    <ResponsiveImg 
                        imgCell={img02cell}
                        alt='banner'
                        onClick={()=>{}}
                    />
                    <Grid container justifyContent='center' gap={3} sx={{padding:'7% 5%'}}>
                        <Typography sx={{
                            width:'100%',
                            fontSize:'12pt',
                            textAlign:'center',
                            color: colors.white,
                            fontWeight:700,
                        }}>
                            {textosCell.text1.title}
                        </Typography>
                        <Typography sx={{
                            fontSize:'11pt',
                            textAlign:'center',
                            color: colors.white,
                        }}>
                            {textosCell.text1.body1}<br/>{textosCell.text1.body2}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={0} md={12} sx={{display:{xs:'none', md:'block'}}}>
                    <Grid container alignItems='center' justifyContent='flex-end' pr={13} sx={{
                        backgroundImage: `url(${img02desk})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat', 
                        backgroundPosition: 'center', 
                        height: '600px',
                        width: 'auto'
                    }}>
                        <Grid item xs={4} container justifyContent='flex-end' gap={5}>
                            <Typography sx={{
                                width:'100%',
                                fontSize:'22pt',
                                textAlign:'right',
                                color: colors.white,
                                fontWeight: 700,
                            }}>
                                {textosDesktop.text1.title}
                            </Typography>
                            <Typography sx={{
                                fontSize:'20pt',
                                textAlign:'right',
                                color: colors.white,
                            }}>
                                {textosDesktop.text1.body1}<br/>{textosDesktop.text1.body2}
                            </Typography>    
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} container alignItems='flex-end' sx={{background: colors.black}}>
                <Grid item xs={12} md={0} sx={{display:{xs:'block', md:'none'}}}>
                    <ResponsiveImg 
                        imgCell={img03cell}
                        alt='banner'
                        onClick={()=>{}}
                    />
                    <Grid container justifyContent='center' gap={3} sx={{padding:'7% 7% 0% 7%'}}>
                        <Typography sx={{
                            width:'100%',
                            fontSize:'12pt',
                            textAlign:'center',
                            color: colors.white,
                            fontWeight:700,
                        }}>
                            {textosCell.text2.title}
                        </Typography>
                        <Typography sx={{
                            fontSize:'11pt',
                            textAlign:'center',
                            color: colors.white,
                        }}>
                            {textosCell.text2.body1}
                        </Typography>
                    </Grid>
                    <Grid sx={{padding:'7% 5%'}}>
                        {textosCell.text2.body2.map((it, index) => (
                            <Typography key={index} sx={{
                                fontSize:'11pt',
                                color: colors.white,
                                display:'flex',
                                gap:'5px'
                            }}>
                                • {it}
                            </Typography>
                        ))}
                        
                    </Grid>
                </Grid>
                <Grid item xs={0} md={12} sx={{display:{xs:'none', md:'block'}}}>
                    <Grid container alignItems='center' pl={13} sx={{
                        backgroundImage: `url(${img03desk})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat', 
                        backgroundPosition: 'center', 
                        height: '600px',
                        width: 'auto'
                    }}>
                        <Grid item xs={6} container gap={3}>
                            <Typography sx={{
                                width:'100%',
                                fontSize:'22pt',
                                color: colors.white,
                                fontWeight:700,
                            }}>
                                {textosDesktop.text2.title}
                            </Typography>
                            <Typography sx={{
                                fontSize:'20pt',
                                color: colors.white,
                            }}>
                                {textosDesktop.text2.body1}
                            </Typography>
                            <Grid container direction='column' gap={1}>
                                {textosDesktop.text2.body2.map((it, index) => (
                                    <Typography key={index} sx={{
                                        fontSize:'16pt',
                                        color: colors.white,
                                        display:'flex',
                                        gap:'5px',
                                        widht:'100%'
                                    }}>
                                        • {it}
                                    </Typography>
                                ))}
                            </Grid>
                            
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} container alignItems='flex-end' sx={{background: colors.black}}>
                <Grid item xs={12} md={0} sx={{display:{xs:'block', md:'none'}}}>
                    <ResponsiveImg 
                        imgCell={img04cell}
                        alt='banner'
                        onClick={()=>{}}
                    />
                    <Grid container justifyContent='center' gap={3} sx={{padding:'7% 5%'}}>
                        <Typography sx={{
                            width:'100%',
                            fontSize:'12pt',
                            textAlign:'center',
                            color: colors.white,
                            fontWeight:700,
                        }}>
                            {textosCell.text3.title}
                        </Typography>
                        <Typography sx={{
                            fontSize:'11pt',
                            textAlign:'center',
                            color: colors.white,
                        }}>
                            {textosCell.text3.body}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={0} md={12} sx={{display:{xs:'none', md:'block'}}}>
                    <Grid container alignItems='center' justifyContent='flex-end' pr={13} sx={{
                        backgroundImage: `url(${img04desk})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat', 
                        backgroundPosition: 'center', 
                        height: '800px',
                        width: 'auto'
                    }}>
                        <Grid item xs={5} container justifyContent='flex-end' gap={6}>
                            <Typography sx={{
                                width:'100%',
                                fontSize:'22pt',
                                textAlign:'right',
                                color: colors.white,
                                fontWeight: 700,
                            }}>
                                {textosDesktop.text3.title}
                            </Typography>
                            <Typography sx={{
                                fontSize:'20pt',
                                textAlign:'right',
                                color: colors.white,
                            }}>
                                {textosDesktop.text3.body}
                            </Typography>    
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} container alignItems='flex-end' sx={{background: colors.black}}>
                <Grid item xs={12} md={0} sx={{display:{xs:'block', md:'none'}}}>
                    <ResponsiveImg 
                        imgCell={img05cell}
                        alt='banner'
                        onClick={()=>{}}
                    />
                    <Grid container justifyContent='center' gap={3} sx={{padding:'7% 7% 0 7%'}}>
                        <Typography sx={{
                            width:'100%',
                            fontSize:'12pt',
                            textAlign:'center',
                            color: colors.white,
                            fontWeight:700,
                        }}>
                            {textosCell.text4.title}
                        </Typography>
                        <Typography sx={{
                            fontSize:'11pt',
                            textAlign:'center',
                            color: colors.white,
                        }}>
                            {textosCell.text4.body1}
                        </Typography>
                    </Grid>
                    <Grid sx={{padding:'7% 5%'}}>
                        {textosCell.text4.body2.map((it, index) => (
                            <Typography key={index} sx={{
                                fontSize:'11pt',
                                color: colors.white,
                                display:'flex',
                                gap:'5px'
                            }}>
                                • {it}
                            </Typography>
                        ))}
                        
                    </Grid>
                </Grid>
                <Grid item xs={0} md={12} sx={{display:{xs:'none', md:'block'}}}>
                    <Grid container alignItems='center' pl={13} sx={{
                        backgroundImage: `url(${img05desk})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat', 
                        backgroundPosition: 'center', 
                        height: '600px',
                        width: 'auto'
                    }}>
                        <Grid item xs={6} container gap={3}>
                            <Typography sx={{
                                width:'100%',
                                fontSize:'22pt',
                                color: colors.white,
                                fontWeight:700,
                            }}>
                                {textosDesktop.text4.title}
                            </Typography>
                            <Typography sx={{
                                fontSize:'20pt',
                                color: colors.white,
                            }}>
                                {textosDesktop.text4.body1}
                            </Typography>
                            <Grid container direction='column' gap={1}>
                                {textosDesktop.text4.body2.map((it, index) => (
                                    <Typography key={index} sx={{
                                        fontSize:'16pt',
                                        color: colors.white,
                                        display:'flex',
                                        gap:'5px',
                                        widht:'100%'
                                    }}>
                                        • {it}
                                    </Typography>
                                ))}
                            </Grid>
                            
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} sx={{display:{xs:'none',md:'block'}}}>
                <Grid container justifyContent='flex-end' alignItems='center' sx={{
                    backgroundImage: `url(${img06desk})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat', 
                    backgroundPosition: 'center', 
                    height: '500px',
                    width: 'auto'
                }}>
                    <Grid item xs={7} sx={{paddingRight: '4%', display:'flex', flexDirection:'column', gap:'15px'}}>
                        <Typography sx={{
                            width: '100%',
                            fontSize: {xs:'12pt', md:'22pt'},
                            textAlign: {xs:'center', md:'right'},
                            color: colors.white,
                            fontWeight: 700,
                        }}>
                            {textosDesktop.text5.title}
                        </Typography>
                        <Typography sx={{
                            width: '100%',
                            fontSize: {xs:'11pt', md:'20pt'},
                            textAlign: {xs:'center', md:'right'},
                            color: colors.white,
                            fontWeight: {xs:700, md:400}
                        }}>
                            {textosDesktop.text5.body}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} sx={{display:{xs:'block',md:'none'}}}>
                <ResponsiveImg 
                    imgCell={img06cell}
                    alt='banner'
                    onClick={()=>{}}
                />
            </Grid>
           
        </Grid>
    )
}

export default Hellgrun