//Mui:
import { IconButton, Typography } from "@mui/material"
import { WhatsApp } from "@mui/icons-material"
//Utils:
import { colors } from "../../utils/const"
import { whatsApp } from "../../utils/utils"

const WhatsAppFlotante = () => {
    return (
        <IconButton onClick={()=>whatsApp()} sx={{
            background: colors.green,
            color: colors.white,
            width: '65px',
            height: '65px',
            display: 'flex',
            justifyContent:'flex-end',
            flexWrap:'nowrap',
            gap:'14px',
            position: 'fixed',
            bottom: '50px',
            right: '50px',
            ':hover':{
                background: colors.green,
                paddingLeft:'20px',
                width: '280px',
                borderRadius:'150px',
                transition:'width 0.3s, borderradius 0.3s, justifyCOntent 0.3s',
            }
        }}>
            <Typography noWrap sx={{
                fontSize:'14pt',
                fontWeight:600
            }}>Cotizá gratis ahora</Typography>
            <WhatsApp sx={{fontSize: '40px', marginRight:'5px'}}/>
        </IconButton>
    )
}

export default WhatsAppFlotante