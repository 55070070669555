import { useLocation, useNavigate } from "react-router-dom"

export function useHistory(){
    
    const navigate = useNavigate()
    const location = useLocation()
    const rutaActual = location.pathname

    const navigateAndScroll = (route, idSection) => {
        navigate(route) 
        setTimeout(()=>{
            if(idSection){
                let section = document.getElementById(idSection)
                let topOffset = section.offsetTop - 100
                window.scrollTo({top: topOffset, behavior: 'smooth'})
            }else{
                window.scrollTo({top: 0, behavior: 'smooth'})
            }
        },200)
    }

    return { rutaActual, navigateAndScroll }
    
}