//Assets:
import img_01 from '../../assets/productos/01producto.png'

export const dataProductos = [
    {
        img: img_01, 
        title:'Kit Seguridad Hikvision Dvr 4ch', 
        previewItems: [
            'DVR 4CH (con fuente y mouse) HILOOK MOD: DVR-204G-M1', 
            'DISCO RIGIDO DE 320GB',
            '4 CAMARAS BULLET INFRARROJA HILOOK MOD: THC-B110-P'
        ],
        allItems: [
            {
                title: '4-ch 1080p Lite 1U H.265 DVR',
                items: [
                    'Deep learning based human and vehicle targets classification of Motion Detection 2.0',
                    'H.265 Pro+/H.265 Pro/H.265 video compression',
                    'HDTVI/AHD/CVI/CVBS/IP video input',
                    'Audio via coaxial cable',
                    'Up to 5-ch IP camera input (up to 2 MP)',
                    'Encoding ability up to 1080p lite @ 30 fps',
                    'Max. 1200 m for 720p HDTVI signal',
                ]
            },
            {
                title:'1 MP Fixed Mini Bullet Camera',
                items: [
                    'HD 720p bullet camera',
                    'Smart IR: up to 20 m IR distance',
                    'Water and dust resistant (IP66).',
                ]
            },
        ],
        buttonLabel:'VER MÁS', 
        buttonAction:()=>{},
    },
    {
        img: img_01, 
        title:'2 MP ColorVu Fixed Mini Bullet Camera', 
        previewItems: [
            'DVR 8CH (con fuente y mouse) HIKVISION MOD: DS-7208HGHI-M1',
            'DISCO SOLIDO 240GB',
            '4 CAMARAS BULLET INFRARROJA 2MP HIKVISION MOD: DS-​2CE10DF0T-PF (Línea ColorVu, imágenes a color día y noche)',
        ],
        allItems: [
            {
                title: '8-ch 720p 1U H.265 DVR',
                items: [
                    'Deep learning based human and vehicle targets classification of Motion Detection 2.0',
                    'H.265 Pro+/H.265 Pro/H.265 video compression',
                    'HDTVI/AHD/CVI/CVBS/IP video input',
                    'Audio via coaxial cable',
                    'Up to 10-ch IP camera input (up to 5 MP)',
                    'Encoding ability up to 1080p lite @ 15 fps',
                    'Max. 1200 m for 720p HDTVI signal',
                ]
            },
            {
                title:'2 MP ColorVu Fixed Mini Bullet Camera',
                items: [
                    'High quality imaging with 2 MP, 1920 × 1080 resolution',
                    '24/7 color imaging with F1.0 aperture',
                    '3D DNR technology delivers clean and sharp images',
                    '2.8 mm, 3.6 mm, 6 mm fixed focal lens',
                    'Up to 20 m white light distance for bright night imaging',
                    'One port for four switchable signals (TVI/AHD/CVI/CVBS)',
                    'Water and dust resistant (IP67)',
                ]
            },
        ],
        buttonLabel:'VER MÁS', 
        buttonAction:()=>{},
    },
]