//Mui:
import { Grid, Typography } from "@mui/material"
//Utils:
import { colors } from "../../utils/const"
//Components:
import ResponsiveVideo from "../../components/VideoPlayer/ResponsiveVideo"
import ResponsiveImg from "../../components/ResponsiveImg/ResponsiveImg"
import CustomButon from "../../components/CustomButton/CustomButton"
import PaquetePersonalizado from "../../components/PaquetePersonalizado/PaquetePersonalizado"
//Data:
import { dataPaquetes } from "../../components/PaquetePersonalizado/dataPaquetes"
//Assets:
import cell_1 from "../../assets/smartPanics/01_cell.mp4"
import desk_1 from "../../assets/smartPanics/01_desk.mp4"
import cell_2 from "../../assets/smartPanics/02_cell.mp4"
import desk_2 from "../../assets/smartPanics/02_desk.mp4"
import desk_3 from "../../assets/smartPanics/03_desk.jpg"
import cell_3 from "../../assets/smartPanics/03_cell.jpg"
import desk_4 from "../../assets/smartPanics/04_desk.mp4"
import cell_4 from "../../assets/smartPanics/04_cell.mp4"
import cell_5 from "../../assets/smartPanics/05_cell.mp4"
import cell_6 from "../../assets/smartPanics/06_cell.png"
import cell_7 from "../../assets/smartPanics/07_cell.mp4"
import cell_8 from "../../assets/smartPanics/08_cell.jpg"
import desk_8 from "../../assets/smartPanics/08_desk.jpg"
import cell_9 from "../../assets/smartPanics/09_cell.jpg"
import desk_9 from "../../assets/smartPanics/09_desk.jpg"
import cell_10 from "../../assets/smartPanics/10_cell.jpg"
import desk_10 from "../../assets/smartPanics/10_desk.png"
//Utils:
import { routes } from "../../utils/routes"
import { whatsApp } from "../../utils/utils"

const SmartPanicsFlota = () => {

    return (
        <Grid container sx={{background: colors.black}}>

            {/* FONDO NEGRO */}
            <Grid container gap={{xs: 5, md: 3}} justifyContent='center' alignItems='flex-start' pb={10}>
                <section id={routes.SOLUCIONES.children.SMART_PANICS.section} />
                <Grid item xs={12}>
                    <ResponsiveVideo 
                        vidCell={cell_1}
                        vidDesk={desk_1}
                        onClick={()=>{}}
                        autoplay={true}
                        hideControl={true}
                        loop={true}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ResponsiveVideo 
                        vidCell={cell_2}
                        vidDesk={desk_2}
                        onClick={()=>{}}
                        autoplay={true}
                        hideControl={true}
                        loop={true}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ResponsiveImg
                        imgCell={cell_3}
                        imgDesk={desk_3}
                        alt='img'
                        onClick={()=>{}}
                    />
                </Grid>
                <Grid item xs={12} container justifyContent='center'>
                    <ResponsiveVideo 
                        vidCell={cell_4}
                        vidDesk={desk_4}
                        onClick={()=>{}}
                        autoplay={true}
                        hideControl={true}
                        loop={true}
                    />
                    <CustomButon 
                        label='COMENZAR'
                        fontSize={{xs:'10pt', md:'16pt'}}
                        onClick={()=>{}}
                        variant='contained'
                        blackContrast={true}
                    />
                </Grid>
                <Grid item xs={12} mt={4} container sx={{display:{xs:'block', md:'none'}}}>
                    <Grid container justifyContent='center'>
                        <Grid item xs={8}>
                            <ResponsiveVideo 
                                vidCell={cell_5}
                                onClick={()=>{}}
                                autoplay={true}
                                hideControl={true}
                                loop={true}
                            />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography sx={{fontSize:'12pt', color: colors.white, textAlign: 'center', padding:'50px 0 30px 0'}}>
                                Recibí una alerta si tu hijo<br/>no llega a casa dentro del plazo<br/>previsto para su recorrido. 
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid item xs={12}>
                            <ResponsiveImg 
                                imgCell={cell_6}
                                alt='img'
                                onClick={()=>{}}
                            />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography sx={{fontSize:'12pt', color: colors.white, textAlign: 'center', padding:'50px 0 30px 0'}}>
                                Rastreo de Vehículos en Tiempo<br/>Real: Vigila todos tus vehículos<br/>con rastreadores GPS integrados. 
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Grid item xs={8}>
                            <ResponsiveVideo 
                                vidCell={cell_7}
                                onClick={()=>{}}
                                autoplay={true}
                                hideControl={true}
                                loop={true}
                            />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography sx={{fontSize:'12pt', color: colors.white, textAlign: 'center', padding:'50px 0 30px 0'}}>
                                Nunca pierdas de vista a tu familia.<br/>Accedé a la localización exacta en<br/>tiempo real de cada uno de los<br/>miembros de tu grupo familiar.<br/> 
                                Recibí alertas sobre excesos de<br/>velocidad, inactividad y estado de la<br/>batería de sus dispositivos móviles.
                            </Typography>
                        </Grid>
                        <CustomButon 
                            label='MÁS INFORMACIÓN'
                            fontSize={{xs:'10pt', md:'16pt'}}
                            onClick={()=>{}}
                            variant='contained'
                            style={null}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} mt={4} container sx={{display:{xs:'none', md:'block'}}}>
                    <Grid container justifyContent='center'>
                        <Grid item xs={9} container justifyContent='center' alignItems='center'>
                            <Grid item xs={6} container justifyContent='center'>
                                <Grid item xs={{maxWidth:'400px'}}>
                                    <ResponsiveVideo 
                                        vidDesk={cell_5}
                                        onClick={()=>{}}
                                        autoplay={true}
                                        hideControl={true}
                                        loop={true}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={6} container justifyContent='center'>
                                <Typography sx={{fontSize: '24pt', color: colors.white, textAlign: 'right'}}>
                                    Recibí una alerta si tu hijo<br/>no llega a casa dentro  del plazo<br/>previsto para su recorrido. 
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent='center' alignItems='center'>
                        <Grid item xs={9} container justifyContent='center' alignItems='center'>
                            <Grid item xs={6} container justifyContent='center'>
                                <Typography sx={{fontSize: '24pt', color: colors.white, textAlign: 'left'}}>
                                    Rastreo de Vehículos en Tiempo Real: Vigila todos tus vehículos con rastreadores GPS integrados. 
                                </Typography>
                            </Grid>
                            <Grid item xs={6} container justifyContent='center'>
                                <Grid item xs={{maxWidth:'400px'}}>
                                    <ResponsiveImg 
                                        imgDesk={cell_6}
                                        alt='img'
                                        onClick={()=>{}}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent='center' alignItems='center' gap={4}>
                        <Grid item xs={9} container justifyContent='center' alignItems='center'>
                            <Grid item xs={6} container justifyContent='center'>
                                <Grid item xs={{maxWidth:'400px'}}>
                                    <ResponsiveVideo 
                                        vidDesk={cell_7}
                                        onClick={()=>{}}
                                        autoplay={true}
                                        hideControl={true}
                                        loop={true}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={6} container justifyContent='center'>
                                <Typography sx={{fontSize: '24pt', color: colors.white, textAlign: 'right'}}>
                                    Nunca pierdas de vista a tu familia.<br/>Accedé a la localización exacta<br/>en tiempo real de cada uno de<br/>los miembros de tu grupo familiar.<br/>
                                    Recibí alertas sobre excesos de<br/>velocidad, inactividad y estado de la<br/>batería de sus dispositivos móviles.
                                </Typography>
                                <Grid container mt={5} justifyContent='flex-end'>
                                    <CustomButon 
                                        label='MÁS INFORMACIÓN'
                                        fontSize={{xs:'10pt', md:'16pt'}}
                                        onClick={()=>whatsApp()}
                                        variant='contained'
                                        blackContrast={true}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} container gap={2} sx={{display:{xs:'flex',md:'none'}}}>
                    <Grid item xs={12}>
                        <ResponsiveImg
                            imgCell={cell_8}
                            alt='img'
                            onClick={()=>{}}
                        />
                    </Grid>
                    <Grid container justifyContent='center'>
                        <CustomButon 
                            label='MÁS INFORMACIÓN'
                            fontSize={{xs:'10pt', md:'16pt'}}
                            onClick={()=>{}}
                            variant='contained'
                        />
                    </Grid>
                </Grid>
            </Grid>

            {/* FONDO BLANCO */}
            <Grid container justifyContent='center' alignItems='flex-start' sx={{background: colors.white}}>
                <Grid item xs={12} sx={{display:{xs:'none',md:'block'}}}>
                    <ResponsiveImg
                        imgDesk={desk_8}
                        alt='img'
                        onClick={()=>{}}
                    />
                </Grid>
                <Grid item xs={12} container justifyContent='center'>
                    <PaquetePersonalizado 
                        title='Nuestros Planes'                  
                        text1='¿No encontrás el paquete que necesitas? '
                        text2='Ayudanos a diseñar uno personalizado para vos'
                        labelButton='COMENZAR'
                        paquetes={[
                            dataPaquetes.SMART_PANIC_INDIVIDUAL,
                            dataPaquetes.SMART_PANIC_FAMILY,
                            dataPaquetes.SMART_PANIC_FAMILY_XL,
                        ]}
                    />
                </Grid>
            </Grid>

            {/* FONDO NEGRO */}
            <Grid container gap={{xs: 5, md: 3}} justifyContent='center' alignItems='flex-start'>
                <Grid item xs={12}>
                <section id={routes.SOLUCIONES.children.MONITOREO_FLOTA.section} />
                    <ResponsiveImg
                        imgCell={cell_9}
                        imgDesk={desk_9}
                        alt='img'
                        onClick={()=>{}}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ResponsiveImg
                        imgCell={cell_10}
                        imgDesk={desk_10}
                        alt='img'
                        onClick={()=>{}}
                    />
                </Grid>
            </Grid>

        </Grid>
    )
}

export default SmartPanicsFlota