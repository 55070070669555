//Hooks:
import { useHistory } from "../../hooks/useHistory"
//Mui:
import { Grid } from "@mui/material"
//Components:
import Mobile from "./Mobile"
import Desktop from "./Desktop"

const NavMenu = () => {

    const { navigateAndScroll } = useHistory()

    return (
        <>
            {/* MOBILE */}
            <Grid item xs={12} md={0} sx={{display:{xs:'block', md:'none'}}}>
                <Mobile handleNavigate={navigateAndScroll} />
            </Grid>

            {/* DESKTOP */}
            <Grid item xs={0} md={12} sx={{display:{xs:'none', md:'block'}}}>
                <Desktop handleNavigate={navigateAndScroll} />
            </Grid>
        </>
    )
}

export default NavMenu